<template>
  <div style="margin-top: 10px">
    <div style="display: flex; gap: 10px;">
      <b-form-group
        id="input-group-8"
        :label="$t('StartDate')"
        label-for="input-25"
      >
        <date-picker
          v-model="startDate"
          value-type="format"
          format="YYYY-MM-DD"
          type="date"
          :placeholder="$t('SelectD')"
          :clearable="false"
          style="padding-left: 0px;"
        />
      </b-form-group>
      <b-form-group
        id="input-group-8"
        :label="$t('EndDate')"
        label-for="input-25"
      >
        <date-picker
          v-model="endDate"
          value-type="format"
          format="YYYY-MM-DD"
          type="date"
          :placeholder="$t('SelectD')"
          :clearable="false"
          style="padding-left: 0px;"
        />
      </b-form-group>
      <b-form-group
        id="input-select-1"
        :label="$t('Filiale')"
        label-for="select-1"
        class="sales"
      >
        <vue-select
          v-model="filiale"
          :options="filteredStores"
          label="storeName"
          :clearable="false"
          :reduce="(e) => e.storeId"
          :placeholder="$t('Filiale')"
        />
      </b-form-group>
    </div>
    <div
      style="width: 100%;  padding-top: 16px; margin-top: 15px; height: 75vh; overflow-y: scroll;"
      class="scroll"
    >

      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead style="position: sticky; top: 1px">
          <tr style="text-align: center;">
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('ClientName') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th>
              {{ $t('paidAmount') }}
            </th>
            <th>
              {{ $t('Discount') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getItemsSoldByStores.length > 0">
          <tr
            v-for="(items, index) in getItemsSoldByStores"
            :key="index"
            style="text-align: center;"
          >
            <td>
              {{ items.orderNumber }}
            </td>
            <td>
              {{ items.orderType }}
            </td>
            <td>
              {{ items.clientFullName }}
            </td>
            <td>
              {{ items.price }}€
            </td>
            <td>
              {{ items.paidAmount }}€
            </td>
            <td>
              {{ items.discount }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="5"
              style="padding: 11px 16px"
            >
              {{ $t('NoSales') }}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsForOrders > 15"
          v-model="page"
          :total-rows="getTotalItemsForOrders"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      filiale: 'All',
      pageNumber: 1,
      pageSize: 15,
    }
  },
  computed: {
    ...mapGetters(['getStoresDropdown', 'getItemsSoldByStores', 'getTotalItemsForOrders']),
    filteredStores() {
      return this.getStoresDropdown
        .filter((store) => { return store.storeId !== 'unassigned' })
        .map((store) => {
          if (store.storeId === 'all') {
            return {
              storeId: null,
              storeName: 'All',
              storeGLN: null,
            };
          }
          return store;
        });
    },
  },
  watch: {
    startDate(value) {
      if (value > this.endDate) {
        this.endDate = value
      }
      if (this.startDate && this.endDate) {
        this.soldItemsByStores({
          startDate: value,
          endDate: this.endDate,
          storeId: this.filiale,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
      } else {
        this.resetSoldItemsByStores()
      }
    },
    endDate(value) {
      if (value < this.startDate) {
        this.startDate = value
      }
      if (this.startDate && this.endDate) {
        this.soldItemsByStores({
          startDate: this.startDate,
          endDate: value,
          storeId: this.filiale,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
      } else {
        this.resetSoldItemsByStores()
      }
    },
    filiale(value) {
      if (this.startDate && this.endDate) {
        this.soldItemsByStores({
          startDate: this.startDate,
          endDate: this.endDate,
          storeId: value,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        })
      } else {
        this.resetSoldItemsByStores()
      }
    },
  },
  mounted() {
    this.loadStoresByDropdown({
      amountOfRecords: 100,
    })
    this.soldItemsByStores({
      startDate: this.startDate,
      endDate: this.endDate,
      storeId: null,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    })
  },
  methods: {
    ...mapActions(['loadStoresByDropdown', 'resetSoldItemsByStores', 'soldItemsByStores']),
  },
}
</script>

  <style scoped>
.team_table td {
  padding: 16px 9px !important;
}

.sales {
  width: 12%;
}
@media screen and (max-width: 1200px) {
  .scroll {
    overflow-x: scroll;
  }
  .sales {
    width: 21%;
  }
}
  </style>
