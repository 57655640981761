<template>
  <div style="margin-top: 10px">
    <div style="display: flex; gap: 10px;">
      <b-form-group
        id="input-group-8"
        :label="$t('StartDate')"
        label-for="input-25"
      >
        <date-picker
          v-model="startDate"
          value-type="format"
          format="YYYY-MM-DD"
          type="date"
          :placeholder="$t('SelectD')"
          :clearable="false"
          style="padding-left: 0px;"
        />
      </b-form-group>
      <b-form-group
        id="input-group-8"
        :label="$t('EndDate')"
        label-for="input-25"
      >
        <date-picker
          v-model="endDate"
          value-type="format"
          format="YYYY-MM-DD"
          type="date"
          :placeholder="$t('SelectD')"
          :clearable="false"
          style="padding-left: 0px;"
        />
      </b-form-group>
      <b-form-group
        id="input-select-1"
        :label="$t('SelectEmployee')"
        label-for="select-1"
        class="sales"
      >
        <vue-select
          v-model="salesUserId"
          :options="getUsersMultipleRoles"
          :reduce="(e) => e.userID"
          :placeholder="$t('Employees')"
          label="fullName"
          aria-describedby="input-1-live-feedback"
          :clearable="false"
        />
      </b-form-group>
    </div>
    <div
      style="width: 100%;  padding-top: 16px; margin-top: 15px; height: 75vh; overflow-y: scroll;"
      class="scroll"
    >

      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead style="position: sticky; top: 1px">
          <tr style="text-align: center;">
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th>
              {{ $t('paidAmount') }}
            </th>
            <th>
              {{ $t('Discount') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getItemsSoldByUser.length > 0">
          <tr
            v-for="(items, index) in getItemsSoldByUser"
            :key="index"
            style="text-align: center;"
          >
            <td>
              {{ items.orderNumber }}
            </td>
            <td>
              {{ items.articleNumber }}
            </td>
            <td>
              {{ items.price }}€
            </td>
            <td>
              {{ items.paidAmount }}€
            </td>
            <td>
              {{ items.discount }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="5"
              style="padding: 11px 16px"
            >
              {{ $t('NoSales') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      salesUserId: null,
      pageNumber: 1,
      pageSize: 15,
      status: true,
      userID: null,
    }
  },
  computed: {
    ...mapGetters([
      'getItemsSoldByUser',
      'getUsersMultipleRoles',
      'getUserRole',
    ]),
  },
  watch: {
    startDate(value) {
      if (value > this.endDate) {
        this.endDate = value
      }
      if (value && this.endDate && this.salesUserId) {
        this.callSalesByUser();
      }
    },
    endDate(value) {
      if (value < this.startDate) {
        this.startDate = value
      }
      if (value && this.startDate && this.salesUserId) {
        this.callSalesByUser();
      }
    },
    // month(newValue) {
    //   if (newValue && this.year && this.salesUserId) {
    //     this.callSalesByUser();
    //   }
    // },
    // year(newValue) {
    //   if (newValue && this.month && this.salesUserId) {
    //     this.callSalesByUser()
    //   }
    // },
    salesUserId(newValue) {
      if (newValue && this.startDate && this.endDate) {
        this.callSalesByUser()
      }
    },
  },
  mounted() {
    this.usersByMultipleRoles({
      status: this.status,
      object: ['SalesPerson', 'OnlineSales'],
    })
    this.getUsersRole()
  },
  methods: {
    ...mapActions([
      'soldItemsByUser',
      'usersByMultipleRoles',
      'getUsersRole',
    ]),
    callSalesByUser() {
      this.soldItemsByUser({
        startDate: this.startDate,
        endDate: this.endDate,
        salesUserId: this.salesUserId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    },
  },
}
</script>

<style scoped>

.team_table td {
  padding: 16px 9px !important;
}

.sales {
  width: 12%;
}
@media screen and (max-width: 1200px) {
  .scroll {
    overflow-x: scroll;
  }
  .sales {
    width: 21%;
  }
}
</style>
