<template>
  <div>
    <b-sidebar
      id="orders-shows"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="60%"
      :title="$t('OrdersHistory')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('OrdersHistory') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        style="padding:20px;"
      >
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ExecutorName') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                hello
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import moment from 'moment'



export default {
  components: {
  },
  props: [],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getAllOrders']),
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
    },
  },
};
</script>

  <style scoped>
  .team_table td {
    padding: 16px 9px !important;
  }

  </style>
